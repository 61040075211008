<template>
  <div class="login-wrapper h-100">
    <div v-if="isLoading">
      <progress-indicator
        :loading-label="staticText.tryingToLogin"
      ></progress-indicator>
    </div>

    <div v-else>
      <b-container id="form-container-202012231345" class="mx-auto">
        <div
          v-if="showLogin"
          class="login-card"
        >
          <div class="text-center mb-3">
            <a href="https://bao-solutions.com">
              <img
                src="../../../public/img/sidebar-logo.svg"
                alt="bao solutions"
                width="30%"
              />
            </a>
          </div>
          <div>
            <b-alert
              variant="info"
              :show="!!message"
              @dismissed="message = null"
              dismissible
            >
              {{ message }}
            </b-alert>
            <b-form>
              <div v-if="!showCodeInput" class="m-2">
                <b-form-group id="email-label-202012231345" class="input-wrapper">
                  <b-form-input
                    id="email-202012231345"
                    v-model.trim="authData.email"
                    type="email"
                    :placeholder="staticText.emailLabel"
                    :class="!!error ? 'password-error' : ''"
                    :disabled="basicLogin"
                    @input="error = null"
                  ></b-form-input>
                  <span class="input-label">Email</span>
                  <p
                    v-if="!!error && !basicLogin"
                    class="error-message mt-2 mb-0"
                  >
                    {{ error.detail ? error.detail : error }}
                  </p>
                </b-form-group>

                <div v-if="showTeamChoices"
                     class="mb-3"
                >
                  <vue-multiselect :options="availableTeams"
                                   v-model="authData.selectedTeam"
                                   label="name"
                                   track-by="schema_name"
                                   :show-labels="false"
                                   :placeholder="staticText.selectTeamLabel"
                                   @input="changeSchemaOnTeamChange"
                  ></vue-multiselect>
                </div>

                <div
                  v-if="basicLogin"
                >
                  <b-form-group
                    id="password-label-202012231345"
                  > 
                  <b-input-group>
                      <b-form-input
                        id="password-102012030654"
                        v-model.trim="authData.password"
                        :type="showPassword ? 'text' : 'password'"
                        autofocus="autofocus"
                        :placeholder="staticText.passwordLabel"
                        aria-describedby="inputLiveHelp inputLiveFeedback"
                        :class="!!error ? 'password-error' : ''"
                      >
                      </b-form-input>
                     

                     <template #append>
                      <span class="password-control-buttons px-1">
                        <img
                          src="../../../public/img/icons/orange-close-icon.svg"
                          v-if="!!error && authData.password.length"
                          class="reset-password-icon mr-1"
                          @click="resetError"
                        />
                        <i
                          class="show-password-icon text-slate-80"
                          :class="showPassword ? 'fas fa-eye': 'fas fa-eye-slash'"
                          @click="showPassword = !showPassword"
                        />
                      </span>
                     </template>
                    </b-input-group>

                    <p v-if="!!error" class="error-message mt-2 mb-0">
                      {{ error.detail ? error.detail : error }}
                    </p>

                  </b-form-group>
                  <b-button
                    class="mb-3 w-100 px-4 font-size-14"
                    @click="basicLogin = false"
                  >
                    <i class="fas fa-chevron-left"></i>
                    {{ staticText.backLabel }}
                  </b-button>
                </div>

                <b-form-group>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="col-md-12"
                    @click.prevent="handleLogin"
                  >
                    {{ loginBtnLabel }}
                  </b-button>
                </b-form-group>

                <a
                  v-if="basicLogin"
                  class="m-2 mt-0 link"
                  href=""
                  @click.prevent="forgotPasswordLinkClicked()"
                >
                  <img
                    src="../../../public/img/icons/key-icon.svg"
                    class="mr-2"
                  />
                  {{ staticText.forgotPasswordLink }}
                </a>
              </div>

              <two-factor-auth-input
                v-else
                @verify-otp="verifyOTP"
                @update-error="updateErrorMessage"
                @update-message="updateMessage"
              />
            </b-form>
          </div>
        </div>

        <forgot-password
          v-else
          :auth-data-email="authData.email"
          @return-to-login-screen="showLogin = true"
          @update-error="updateErrorMessage"
        />

        <div class="privacy-policy">
          <a href="https://www.bao.ai/datenschutzerklaerung/" target="__blank">Datenschutzerklärung</a>
          <span class="mx-1">/</span>
          <a href="https://www.bao.ai/privacy-policy/?lang=en" target="__blank">Privacy Policy</a>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "./ProgressIndicator"
import VueMultiselect from "vue-multiselect"
import ForgotPassword from "./ForgotPassword.vue"

import {
  clearStorage,
  getEmail,
  getSchemaName,
  getSocialOTT,
  getBaoUserFromStorage,
  isRefreshTokenExpired,
  isSocialAuthInProgress,
  isSocialAuthProgressOldThenClear,
  lookupEmail,
  removeSocialAuthInProgress,
  setEmail,
  setSchemaName,
  clearSchemaInfo,
  setSocialAuthInProgress,
  setSocialOTT,
  setTokenPair,
  areFreshAuthTokens,
  popupLoginForSwift,
  getLocalStorage
} from "@/utils/authenticationManager"

import { setCookie } from "@/utils/utils"
import TwoFactorAuthInput from "./TwoFactorLoginInput.vue"

export default {
  name: "LoginPage",
  components: {
    ProgressIndicator,
    ForgotPassword,
    VueMultiselect,
    TwoFactorAuthInput
  },
  data () {
    return {
      authData: {
        email: "",
        password: "",
        selectedTeam: null
      },
      showPassword: false,
      showTeamChoices: false,
      availableTeams: [],
      axios,
      showCodeInput: false,
      error: null,
      shouldAttemptTokenNP: !!this.getSocialOTT(),
      popupWindow: null,
      isLoading: false,
      message: null,
      showLogin: true,
      basicLogin: false,
      loginPath: null,
      staticText: {
        selectTeamLabel: "Select Team",
        incorrectCredentialsAlert: "Username or password incorrect!",
        invalidEmailText: "Email address is not valid",
        passwordLabel: "Password",
        forgotPasswordLink: "Forgot your password?",
        iFrameLoginBtnLabel: "Login",
        backLabel: "Back",
        tryingToLogin: "Loading ...",
        wrongAccountSelected: "Make sure you are logged in with your social account and complete login process with correct account"
      }
    }
  },
  computed: {
    ...mapGetters({ authenticatedUser: "auth/user" }),
    loginBtnLabel () {
      return this.basicLogin ? "Login" : "Submit"
    },
    changeSchemaOnTeamChange () {
      return (selectedTeam) => {
        this.setSchemaName(selectedTeam.schema_name)
      }
    }
  },
  async mounted () {
    // set active speech recognition to False when user lands on login page
    setCookie("activeSpeechRecognition", "false")

    this.authData.email = this.lookupEmail()

    await this.getUserDataIfTokenPresent()

    this.redirectUserIfAlreadyLoggedIn()

    this.autoLoginUserAfterSsoVerification()

    this.clearOldSsoDataIfApplicable()

    this.displayCustomErrIfApplicable()

    this.clearStorageIfApplicable()

    this.closeSsoPopupIfPresent()

    this.handleSsoLoginRedirect()
  },
  methods: {
    ...mapActions({
      getUser: "auth/getUser",
      updateLanguage: "I18nStore/getI18nData"
    }),
    setTokenPair,
    setSchemaName,
    getSchemaName,
    clearSchemaInfo,
    getBaoUserFromStorage,
    clearStorage,
    getSocialOTT,
    setSocialOTT,
    getEmail,
    setEmail,
    lookupEmail,
    getLocalStorage,
    isRefreshTokenExpired,
    isSocialAuthInProgress,
    setSocialAuthInProgress,
    removeSocialAuthInProgress,
    isSocialAuthProgressOldThenClear,
    areFreshAuthTokens,
    popupLoginForSwift,
    resetError () {
      this.error = null
      this.authData.password = ""
    },
    isTokenAvailable () {
      const accessToken = this.getLocalStorage("access_token")
      const refreshToken = this.getLocalStorage("refresh_token")
      const refreshTokenCreatedAt = this.getLocalStorage("refresh_token_created_at")
      if (accessToken && refreshToken && refreshTokenCreatedAt) {
        return true
      }
      return false
    },
    async verifyOTP (otp) {
      this.authData.code = otp

      await this.performLogin("/api/token/", this.authData, true)
    },
    async handleLogin () {
      // reset the error messages.
      this.error = null

      if (this.basicLogin) {
        // default email/password login
        this.authData.schema_name = this.getSchemaName()
        this.performLogin("/api/token/", this.authData, true)
      } else {
        // sso login
        await this.handleSocialLogin()
      }
    },
    async performLogin (path, authData, allow2FA = false) {
      this.isLoading = true
      await this.axios
        .post(path, authData)
        .then(response => {
          if (allow2FA && response.status === 201) {
            // If 2FA flow
            this.isLoading = false
            this.showCodeInput = true
            return
          }
          // Login was successful
          // save new tokens
          // clear localStorage of socialOTT and email
          const { access, refresh } = response.data
          const schemaName = response.data.schema_name
          this.setTokenPair(access, refresh)
          this.setSchemaName(schemaName)

          this.showCodeInput = false
        })
        .then(() => {
          this.getUserAndUpdateLanguage()
        })
        .catch(err => {
          if (err.response) {
            this.handleError(err.response)
            this.isLoading = false
          }
          this.clearStorage()
          this.clearSchemaInfo()
        })
    },
    async handleSocialLogin () {
      const email = this.authData.email
      const tenant = this.authData.selectedTeam
      this.setEmail(email) // email saved in cookie
      await this.axios
        .post("/api/login/", { email, tenant }, {
          validateStatus: function (status) {
            return status >= 200 && status <= 300
          }
        })
        .then(response => {
          if (response.status === 300) {
            this.showTeamChoices = true
            this.availableTeams = response.data
            return
          }
          if (response.status === 200) {
            this.showTeamChoices = false
            const { loginPath, socialOTT, schemaName } = response.data

            this.setSchemaName(schemaName)
            if (!loginPath) {
              this.basicLogin = true
            } else {
              // trigger social login
              this.setSocialAuthInProgress()
              this.setSocialOTT(socialOTT)
              this.isLoading = true
              this.loginPath = loginPath

              this.openSocialLoginTab()
            }
          }
        })
        .catch(err => {
          console.log(err.response)
          this.error = this.staticText.invalidEmailText
        })
    },
    openSocialLoginTab () {
      if (this.isApplicationInIFrame) {
        this.handleSocialLoginInIframe()
      } else {
        // If not in iframe, then redirect to social login page
        const SOCIAL_LOGIN_URL = process.env.VUE_APP_BASEURL + this.loginPath
        window.location.replace(SOCIAL_LOGIN_URL)
      }
    },
    handleSocialLoginInIframe () {
      // if window is in a iframe, then open a popup or tab
      this.messageBaoSwift({ type: "ssoLoginInitiated" })

      this.createTabOrPopup(this.popupLoginForSwift())

      // stop loading after 60 seconds if login was not yet successful
      setTimeout(() => {
        this.error = this.staticText.wrongAccountSelected
        this.isLoading = false
      }, 60000)
    },
    createTabOrPopup (popupLogin = false) {
      // create window popup for social authentication
      const SOCIAL_LOGIN_URL = process.env.VUE_APP_BASEURL + this.loginPath
      const windowName =
        "bao_social_oauth_login_" + Math.floor(Math.random() * 100)

      let newWindowAttr = [SOCIAL_LOGIN_URL, windowName]

      if (popupLogin) {
        newWindowAttr = newWindowAttr.concat(this.getPopupWindowDimensions())
      }
      window.open(...newWindowAttr)
    },
    getPopupWindowDimensions () {
      // Passing the following window attributes (dimensions) as a string creates a popup window.
      // Otherwise its a new tab which we'd auto close.

      const popupHeight = 800
      const popupWidth = 800
      const popupTop = (window.innerHeight - popupHeight) / 2
      const popupLeft = (window.innerWidth - popupWidth) / 2

      return [`width=${popupHeight},height=${popupWidth},top=${popupTop},left=${popupLeft}`]
    },
    forgotPasswordLinkClicked () {
      this.showLogin = false
    },
    getUserAndUpdateLanguage () {
      this.getUser()
        .then(() => {
          this.isLoading = this.basicLogin || this.isSocialAuthInProgress()
          this.removeSocialAuthInProgress()
        })
        .then(() => {
          this.updateLanguage()
          const query = this.$route.query
          if (query.redirect && query.redirect.length > 0) {
            this.pushOrReplaceRoute(query.redirect)
          } else {
            this.pushOrReplaceRoute({ path: "/" })
          }
        })
    },
    handleError (errorResponse) {
      if (this.basicLogin) {
        if (errorResponse.status === 401) {
          this.error = this.staticText.incorrectCredentialsAlert
        } else if (errorResponse.data) {
          let errorData = errorResponse.data
          errorData = errorData.message
            ? errorData.message
            : errorData.detail
          this.error = errorData || this.staticText.incorrectCredentialsAlert
        }
      } else {
        this.error = this.staticText.wrongAccountSelected
      }
    },
    updateErrorMessage (error) {
      this.error = error
    },
    updateMessage (message) {
      this.message = message
    },
    async getUserDataIfTokenPresent () {
      // if token is available but the bao-user not available then call the api to get it
      // this method (this.getUser()) calls two api: api/users/get_integrated_crms, api/users/profile/
      if (!this.getBaoUserFromStorage() && this.isTokenAvailable()) {
        await this.getUser()
      }
    },
    redirectUserIfAlreadyLoggedIn () {
      const query = this.$route.query

      const user = this.getBaoUserFromStorage()
      this.message = query && query.message ? query.message : null
      if (query && user) {
        this.isLoading = true
        this.getUser()
          .then(() => {
            if (query.redirect && query.redirect.length > 0) {
              this.pushOrReplaceRoute(query.redirect)
              return
            }
            this.pushOrReplaceRoute("/")
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },
    autoLoginUserAfterSsoVerification () {
      // no-password token login
      // When SSO is triggered, socialAuthInProgress is set to true, and the socialOTT value
      // is also saved to local storage. After SSO verification, we check this and proceed
      // to auto-login the user.
      if (this.shouldAttemptTokenNP && this.isSocialAuthInProgress()) {
        this.performLogin(
          "/api/token-np/",
          {
            email: this.getEmail(),
            socialOTT: this.getSocialOTT()
          },
          false
        )
      }
    },
    handleSsoLoginRedirect () {
      // After the sso authentication, the user is re-directed back to the login page; hence the redirectUrl
      // origin has to be one of the google or microsoft auth domain
      // In case of sso triggered via the browser extension, getSocialOTT would have no value on this redirected
      // page and this would have been saved on the application instance on the extension.
      // In this case, we close this page and reload the extension
      const redirectFromURL = document.referrer ? new URL(document.referrer) : null

      if (redirectFromURL) {
        const ssoOAuthOrigins = ["https://accounts.google.com", "https://login.microsoftonline.com"]
        const isOpenFromOAuth = ssoOAuthOrigins.includes(redirectFromURL.origin)
        const urlRedirect = this.$route.query.redirect

        // TODO: This has to be improved with new SSO flow improvement ticket
        // https://bao-solutions.atlassian.net/browse/BCS-3495

        if (isOpenFromOAuth && !getSocialOTT() && !urlRedirect) {
          // This means it was opened from bao SWIFT as an SSO login redirect

          this.messageBaoSwift({ type: "reloadApplication" }, false)
            .then(() => {
              // close the current window
              window.close()
            })
        }
      }
    },
    closeSsoPopupIfPresent () {
      // Remove the popup window if it is still open after social login
      // and login screen opens up in the popup window
      if (
        window.opener &&
        window.opener !== window &&
        window.location.pathname === "/login"
      ) {
        // make the opener window reload
        window.opener.location.reload()
        window.close()
      }
    },
    displayCustomErrIfApplicable () {
      if (!this.isSocialAuthInProgress() && localStorage.getItem("custom_err")) {
        // set by backend template
        this.error = localStorage.getItem("custom_err")
        localStorage.removeItem("custom_err")
      }
    },
    clearStorageIfApplicable () {
      // If refresh token is present and has expired,
      // then don't show loading
      if (this.isRefreshTokenExpired()) {
        this.isLoading = false
        this.clearStorage()
      }
    },
    clearOldSsoDataIfApplicable () {
      // clear old SSO data
      if (this.isSocialAuthProgressOldThenClear()) {
        this.isLoading = false
        this.error = null
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.form-control:disabled,
.form-control[readonly] {
  padding-bottom: unset;
  padding-top: unset;
}

.form-input input:not(:placeholder-shown):valid {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $background;
  backdrop-filter: blur(20px);
}

.login-card {
  width: 410px;
  padding: 40px;
  background-color: $white40;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  border-radius: 20px;
}

@media (max-width: 440px) {
  .login-card {
    padding: 12px;
    width: 100%;
  }
}

.link {
  color: $slate80;
}

.password-error {
  border: 1px solid $orange80;
  border-radius: 12px;
}

.error-message {
  font-size: 12px;
  line-height: 14px;
  color: $orange;
}

.password-control-buttons {
  border-radius: 0 12px 12px 0;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  min-width: 30px;

  .reset-password-icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-left: -28px;
    z-index: 2;
  }

  .show-password-icon {
    cursor: pointer;
  }
}


.privacy-policy {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 31px;
  a {
    text-decoration: underline;
  }
}
</style>
